var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-filters text-right"},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.filterBy")))]),_c('div',{staticClass:"d-inline-block ml-5",staticStyle:{"width":"240px"}},[_c('date-range-picker',{attrs:{"textFieldAttrs":{
          solo: true,
          placeholder: _vm.$t('labels.date'),
          hideDetails: true,
        },"on":{
          change: _vm.fetchData,
        }},on:{"change":_vm.fetchData},model:{value:(_vm.filters.date),callback:function ($$v) {_vm.$set(_vm.filters, "date", $$v)},expression:"filters.date"}})],1),_c('v-select',{staticClass:"d-inline-block ml-5",staticStyle:{"width":"200px"},attrs:{"items":_vm.$store.getters['movieGenres/items'],"placeholder":_vm.$t('labels.genre'),"clearable":"","item-text":"name","item-value":"id","solo":""},on:{"change":_vm.fetchData},model:{value:(_vm.filters.genre),callback:function ($$v) {_vm.$set(_vm.filters, "genre", $$v)},expression:"filters.genre"}})],1),_c('v-data-table',{staticClass:"products-table row-clickable",attrs:{"headers":_vm.headers,"items":_vm.$store.getters['products/items'],"items-per-page":_vm.per_page,"page":_vm.page,"loading":_vm.$store.getters['products/loading'].fetchAll,"server-items-length":_vm.$store.getters['products/total'],"footer-props":_vm.$config.vDataTable.footerProps,"disable-sort":""},on:{"update:itemsPerPage":function($event){_vm.per_page=$event},"update:items-per-page":[function($event){_vm.per_page=$event},_vm.fetchData],"update:page":[function($event){_vm.page=$event},_vm.fetchData],"click:row":function (item) { return _vm.rowClick(item); }},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-select',{class:'status-' + item.status,attrs:{"items":_vm.statusItems,"loading":_vm.$store.getters['products/loading'].update == item.id,"hide-details":"","solo":""},on:{"change":function($event){return _vm.updateStatus(item, $event)},"click":function($event){$event.stopPropagation();}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatDate(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","close-on-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',{staticClass:"text-right"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)],1)]}}],null,true),model:{value:(_vm.menu[item.id]),callback:function ($$v) {_vm.$set(_vm.menu, item.id, $$v)},expression:"menu[item.id]"}},[_c('v-card',{attrs:{"width":"150"}},[_c('confirm-dialog',{attrs:{"button-text":_vm.$t('buttons.delete'),"button-icon":"mdi-trash-can-outline","button-attrs":{
              class: 'btn-justify-left',
              block: true,
              color: 'transparent',
              loading: _vm.$store.getters['products/loading'].delete == item.id,
            }},on:{"confirm":function($event){return _vm.deleteProduct(item)}}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }